.container {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
}

.inputContainer {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
}

.inputContainer:not(:hover) .clear {
  display: none;
}

.input {
  transition: all 0.3s;
  outline: none;
  border: 0.1rem solid #d9d9d9;
  border-radius: 0.125rem 0 0 0.125rem;
  padding: 0.4rem 0.7rem;
  width: 100%;
  height: 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
}

.input::placeholder {
  color: #bfbfbf;
}

.input:placeholder-shown {
  text-overflow: ellipsis;
}

.input:placeholder-shown + .clear {
  display: none;
}

.clear {
  position: absolute;
  top: 50%;
  right: 0.25rem;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
}

.search {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
  border: 0.1rem solid transparent;
  border-radius: 0 0.2rem 0.2rem 0;
  padding: 0 0.95rem;
  height: 3.125rem;
}

.clear,
.search {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
