@value secondary-font, main-font, light-peach, main-dark, tablet, desktop-s, desktop-m, desktop-l from "@/styles/variables.module.css";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background-color: light-peach;
  padding: 0px 32px;
  width: 100%;
  height: 100%;
  min-height: 732px;
  color: main-dark;
}

.dark {
  background-color: main-dark;
  color: #fff;
}

.dark .bookmark {
  filter: invert(1);
}

.topSection {
  display: flex;
  align-items: center;
  gap: 12px;
  color: inherit;
  font-weight: lighter;
  font-size: 10px;
  font-family: secondary-font;
}

.category {
  font-weight: 500;
  text-transform: uppercase;
}

.header {
  font-style: normal;
  font-weight: lighter;
  font-size: 24px;
  line-height: 36px;
  font-family: main-font;
  letter-spacing: 0.24px;
  text-align: center;
}

.description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  align-self: stretch;
  overflow: hidden;
  color: inherit;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  line-height: 28px;
  font-family: secondary-font;
  text-align: center;
  text-overflow: ellipsis;
}

.button {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid main-dark;
  border-radius: 2px;
  padding: 20px 24px;
  height: 48px;
  font-weight: lighter;
  font-size: 14px;
  font-family: secondary-font;
}

.dark .button {
  border-color: #fff;
}
