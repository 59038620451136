@value max-small, max-medium from "@/styles/variables.module.css";

.subHeader {
  margin-top: 25px;
  font-weight: 700;
  font-size: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

@media max-medium {
  .subHeader {
    padding-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }
}

.text {
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
}

.textBold {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.howsToContainer {
  display: flex;
  margin-top: 25px;
  gap: 24px;
}

@media max-medium {
  .howsToContainer {
    flex-direction: column;
  }
}

.howsTo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  row-gap: 24px;
}

.divider {
  margin-top: 50px;
  background-color: #999999;
  width: 100%;
  height: 1px;
}

.howsImagesSection {
  margin-top: 30px;
}

.howsImagesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 30px;
}

.howsImage {
  width: 100%;
  height: 100%;
}

.buttonContainer {
  display: flex;
  column-gap: 10px;
  justify-content: center;
}

.button {
  border: 2px solid #000000;
  padding: 15px 18px;
}

@media max-small {
  .buttonContainer {
    flex-direction: column;
    width: 100%;
  }
}
