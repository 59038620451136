.textarea {
  border: 1px solid #d9d9d9;
  padding: 16px;
  max-width: 100%;
  resize: vertical;
  font-size: 14px;
  font-family: 'Oxygen';
  border-radius: 0;
}

.textarea:focus {
  outline: none;
  border: 1px solid #000000;
}
