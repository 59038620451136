@value main-dark, light-peach, secondary-font from "@/styles/variables.module.css";

.typography {
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;
  font-family: secondary-font;
  letter-spacing: 0.16px;
}

.wrapper {
  position: relative;
  flex: 1;
}

.button {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid main-dark;
  padding: 20px 16px;
  color: #000;
}

.options {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;
  margin: 0;
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  max-height: 330px;
  overflow-y: auto;
  color: #000000;
  list-style: none;
}

.currentValue {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  color: main-dark;
  font-style: normal;
  font-weight: 600;
}

.options > li {
  width: 100%;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  background-color: light-peach;
  padding: 20px 16px;
  width: 100%;
  font-size: 14px;
}

.selected {
  background-color: #000000;
  color: #ffffff;
}

.active {
  background-color: #f5f5f5;
}

.active.selected {
  background-color: #000;
  color: #fff;
}

.option:hover {
  background-color: #000;
  color: #fff;
}

.option.selected:hover {
  background-color: #000;
  color: #fff;
}

.transitionLeave {
  transition-duration: 100ms;
  transition-property: all;
  transition-timing-function: ease-in;
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 1;
}

.listboxOption {
  width: 100%;
}
