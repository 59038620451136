@keyframes showTooltip {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tooltip {
  display: flex;
  position: absolute;
  top: 120%;
  left: 15%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 1000;
  animation: showTooltip 0.3s cubic-bezier(0.45, 0, 0.55, 1) forwards;
  box-shadow: 1px 6px 8px -5px #cc000057;
  border-radius: 5px;
  background-color: #f0e4e1;
  width: 230px;
  height: 100px;
  color: #803026;
}

.tooltip::before {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 100px;

  font-size: 0.7rem;
}

.tooltip::after {
  display: block;
  position: absolute;
  top: -5px;
  left: 5px;
  margin: 0 auto;
  border-top: 1px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #f0e4e1;
  border-left: 5px solid transparent;
  background: transparent;
  width: 0;
  height: 0;
  content: '';
}
