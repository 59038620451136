.specialCardWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.selectsContainer {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 30px;
}

.selectContainer {
  min-width: 45%;
}
