.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  row-gap: 24px;
}

.quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.message {
  font-size: 16px;
  font-family: 'Oxygen';
}

.name {
  font-weight: 700;
  font-size: 16px;
}
