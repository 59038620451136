@value max-small, max-medium, max-large, max-xlarge, min-large, min-medium from "@/styles/variables.module.css";

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media min-large {
  .sectionWrapper {
    flex-direction: row;
  }
}

.sectionLeftWrapper,
.sectionRightWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media min-large {
  .sectionLeftWrapper {
    width: 65%;
  }
}

@media min-large {
  .sectionRightWrapper {
    width: 35%;
  }
}

.contentLeftWrapper,
.contentRightWrapper {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
}

.heading {
  margin: 0;
  font-weight: 700;
  font-size: clamp(16px, 4vw, 24px);
  font-family: Oxygen;
  text-transform: uppercase;
  white-space: pre-wrap;
}

.websiteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1.5px solid #b3b3b3;
  padding: 15px 18px;
  width: 133px;
  height: 33px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
}

.sliderWrapper {
  display: flex;
  row-gap: 32px;
  flex-direction: column;
  justify-content: center;
}
