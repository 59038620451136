@value peach, main-dark, modalZIndex, secondary-font, main-font, min-xxlarge from '@/styles/variables.module.css';

.navOverlay {
  position: fixed;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.25);
  width: 100dvw;
  height: 100dvh;
}

.mainNav {
  position: relative;
}

.navList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 60px;
  list-style: none;
}

.navItem:hover > .navLink {
  cursor: pointer;
  color: peach;
}

.navLink {
  transition: color 0.3s ease;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 48px;
  font-family: secondary-font;
  text-wrap: nowrap;
}

.dropdown {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  justify-content: center;
  z-index: modalZIndex;
  background: #fff;
  width: 100%;
  min-height: 480px;
}

.dropdownWithSlider {
  @media min-xxlarge {
    background: linear-gradient(to right, #fff 75%, main-dark 10%);
  }
}

.dropdownOpen {
  display: flex;
}

.dropdownWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
}

.dropdownNavWrapper {
  display: flex;
  column-gap: 40px;
  padding-top: 67px;
  padding-right: 68px;
  padding-left: 34px;
}

.subcategoryTitleWrapper {
  display: flex;
  flex-direction: column;
  gap: 18px;
  min-width: 200px;
  max-height: 74px;
}

.subcategoryTitle {
  color: main-dark;
  font-weight: 400;
  font-size: 32px;
  line-height: 24px;
  font-family: main-font;
}

.subcategoryTitle:hover {
  color: main-dark;
}

.subcategoryTitleText {
  color: main-dark;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
}

.subcategoryTitleText:hover {
  color: main-dark;
}

.navListSubcategory {
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  margin: 0;
  padding: 0;
  padding-right: 40px;
  list-style-type: none;
}

.navSubItem {
  min-width: 125px;
}

.navSubRowTitle {
  color: main-dark;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
  text-align: left;
}

.navLinkSubcategory {
  margin: 0;
  padding: 0;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
}

.navLinkSubcategory:hover {
  color: peach;
}

.sliderContainer {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
  background-color: main-dark;
  padding: 16px 26px;
  width: 100%;
  min-width: 150px;
  max-width: 50%;
  height: 480px;
}

.title {
  color: #fff;
  font-weight: 600;
  font-size: 12px;
  line-height: 32px;
  font-family: secondary-font;
  letter-spacing: 0.06em;
  text-align: left;
  text-transform: uppercase;
}
