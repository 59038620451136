@value max-small, max-medium, max-large, max-xlarge, min-small, min-medium, min-large, min-xlarge from "@/styles/variables.module.css";

.container {
  display: flex;
}

.list {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;

  .list {
    gap: 0.5rem;
  }
}

[dir='rtl'] .list {
  gap: revert;
}

.link {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  @media max-medium {
    font-size: 12px;
  }
}

[dir='rtl'] .svgIcon {
  transform: rotate(180deg);
}
