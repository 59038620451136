.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 400px;
}

.error {
  color: red;
}

.xButton {
  position: absolute;
  top: 4px;
  right: 4px;
}

.background {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f5f2f0;
  padding: 38px;
}

.header {
  font-weight: 400;
  font-size: 26px;
  line-height: 30px;
  font-family: DMSerifDisplay;
  text-align: center;
}

.orContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}

.line {
  flex: 1;
  background-color: #000000;
  height: 1px;
}

.orText {
  padding: 0 10px;
  color: #000000;
  font-weight: 700;
  font-size: 12px;
  font-family: Oxygen;
  letter-spacing: 0.1em;
}

.label {
  display: block;
  margin-bottom: 6px;
  font-weight: 300;
  font-size: 14px;
  font-family: Oxygen;
}

.input {
  width: 100%;
}

.input:first-of-type {
  margin-bottom: 8px;
}

.submitButton {
  width: 100%;
}

.footer {
  background: #000000;
  padding: 16px 0px 16px 0px;
  width: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: Oxygen;
  text-align: center;
  text-align: center;
}

.footer > button {
  display: inline;
  cursor: pointer;
  margin: 0;
  border: none;
  background: unset;
  padding: 0;
  color: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: Oxygen;
  text-align: center;
  text-align: center;
  text-decoration: underline;
}

.socialButton {
  margin-bottom: 10px;
}

.inputDescription {
  display: inline-block;
  margin-top: 4px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: Oxygen;
  text-align: left;
}
