.starBlack path {
  fill: #000000 !important;
}

.starBlack polygon {
  stroke: #000000 !important;
}

.icon {
  cursor: pointer;
}

.buttonIcon {
  margin-right: 10px;
}
