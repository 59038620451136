.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  padding: 10px;
  width: 400px;
  height: 100px;
}

.buttonsContainer {
  display: flex;
  gap: 10px;
}

.button {
  cursor: pointer;
  border: solid 1px #d9d9d9;
  background-color: #fff;
  padding: 4px 8px;
}
