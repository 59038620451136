@value desktop-l,desktop-m,desktop-s,tablet,main-font,secondary-font from "@/styles/variables.module.css";

.sliderItem {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: 1px solid #131313;
  border-radius: 24px;
  background-color: #fff;
  padding: 4px 12px;
  height: 32px;
  color: #131313;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
  text-align: center;
  white-space: nowrap;

  @media tablet {
    padding: 6px 12px;
    height: 40px;
  }

  @media desktop-s {
    padding: 10px 16px;
  }
}

.sliderItem.active {
  background: #131313;
  color: #fff;
}
