@value max-small, max-medium, max-large, max-xlarge, min-small, min-medium, min-large, min-xlarge from "@/styles/variables.module.css";

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.noDataHeader {
  margin: 12px 0;
  color: #000000;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;

  @media max-medium {
    font-size: 20px;
    line-height: 30px;
  }
}

.noDataSubHeader {
  margin: 12px 0;
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;

  @media max-medium {
    font-size: 16px;
    line-height: 26px;
  }
}

.noDataList {
  width: 350px;
}

.noDataListItem {
  color: #000000;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;

  @media max-medium {
    font-size: 16px;
    line-height: 26px;
  }
}
