.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

@media only screen and (max-width: 600px) {
  .grid-container {
    grid-template-columns: 1fr;
  }
}

@media only screen and (min-width: 901px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
