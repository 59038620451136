@value max-medium from "@/styles/variables.module.css";

.container {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
}

.quotes {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
}

.message {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
}

.name {
  margin-top: 20px;
  font-weight: 700;
  font-size: 16px;
  font-family: Oxygen;
}

.quoteWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  max-width: 790px;
  text-align: center;
}

.subHeader {
  margin-top: 25px;
  margin-bottom: 13px;
  font-weight: 700;
  font-size: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

@media max-medium {
  .subHeader {
    padding-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }
}
