.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 14px;
}

.wrapperOption {
  width: 100%;
}

.category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
}

.selected {
  background-color: #000000;
  color: #ffffff;
  font-weight: 600;
}

.active {
  background-color: #f5f5f5;
}

.active.selected {
  background-color: #000000;
  color: #ffffff;
}

.option:hover {
  background-color: #f5f5f5;
}

.option.selected:hover {
  background-color: #000000;
  color: #ffffff;
}

.arrowUp {
  transform: rotate(180deg);
}
