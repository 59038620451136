.button {
  height: 50px;
  width: 100%;
  border: 1px solid #d9d9d9;
  background-color: #ffffff;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
}
