@value max-small, max-medium, max-large, max-xlarge, min-large from "@/styles/variables.module.css";

.heroCard {
  display: flex;
  flex-direction: column;
  place-content: center;
  background: white;
  padding: 54px 69px;
  width: 750px;
  height: 100%;

  @media max-xlarge {
    margin: 0 auto;
    width: 100%;
  }

  @media max-medium {
    padding: 30px;
    width: 100%;
    height: auto;
  }
}

.heroCardHeader {
  margin-top: 0;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 400;
  font-size: 70px;
  line-height: 68px;
  font-family: DMSerifDisplay;

  @media max-xlarge {
    font-size: 56px;
    line-height: 54px;
  }

  @media max-large {
    font-size: 44px;
    line-height: 42px;
  }

  @media max-small {
    margin-bottom: 0;
    font-size: 26px;
    line-height: 30px;
  }
}

.heroCardText {
  margin-top: 16px;
  width: 500px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;

  @media min-large {
    margin-bottom: 44px;
  }

  @media max-medium {
    width: auto;
  }

  @media max-small {
    font-size: 16px;
    line-height: 24px;
  }
}

.heroCardTextUpper {
  margin-bottom: 0;

  @media max-small {
    margin-bottom: -12px;
  }
}

.heroLinks {
  display: flex;
  gap: 12px;

  @media max-medium {
    flex-direction: column;
  }
}

.linkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 18px 15px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Oxygen', sans-serif;
  letter-spacing: 1.2px;
  text-transform: uppercase;

  @media max-large {
    width: 100%;
  }

  @media max-medium {
    padding: 12px 8px;
  }
}
