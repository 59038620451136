@value max-small, max-xlarge, min-small, max-medium from "@/styles/variables.module.css";

.listTitle {
  margin-top: 24px;
  background-color: rgb(214, 203, 190);
  padding: 20px 30px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  color: #ffffff;
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  text-align: center;

  @media max-xlarge {
    margin-top: 30px;
  }
  @media max-large {
    margin-top: 0;
  }
}

.container {
  margin: 0;
  margin-top: 12px;
  margin-bottom: -24px;

  @media max-medium {
    margin-top: 24px;
    margin-bottom: -36px;
  }
}
