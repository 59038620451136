@value desktop-l,desktop-m,desktop-s,tablet from "@/styles/variables.module.css";

.quickFilters {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  width: 100%;
  overflow-x: scroll;
}

.quickFilters::-webkit-scrollbar {
  display: none;
}
