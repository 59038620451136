@value max-small, max-medium, max-large, max-xlarge, min-large, main-font from "@/styles/variables.module.css";

.heroCard {
  display: flex;
  flex-direction: column;
  place-content: center;
  background: white;
  padding: 54px 69px;
  width: 750px;
  height: 100%;

  @media max-xlarge {
    margin: 0 auto;
    width: 100%;
  }

  @media max-medium {
    padding: 30px;
    width: 100%;
    height: auto;
  }
}

.heroCardV3Font {
  /* TODO: move it to .heroCardHeader after removing ff without !important */
  font-family: main-font !important;
}

.heroCardHeader {
  margin-top: 0;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 400;
  font-size: 70px;
  line-height: 68px;
  font-family: DMSerifDisplay;

  @media max-xlarge {
    font-size: 56px;
    line-height: 54px;
  }

  @media max-large {
    font-size: 44px;
    line-height: 42px;
  }

  @media max-small {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 30px;
  }
}

.heroCardText {
  width: 500px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;

  @media min-large {
    margin-bottom: 44px;
  }

  @media max-medium {
    width: auto;
  }

  @media max-small {
    font-size: 16px;
    line-height: 24px;
  }
}

.linkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 180px;
  height: 3rem;
  color: white;
  font-weight: bold;
  font-size: 0.75rem;
  font-family: 'Oxygen', sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media max-large {
    width: 100%;
  }
}

.heroPhotographerButtonWrapper {
  display: flex;
  align-items: flex-start;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;

  @media max-medium {
    margin-top: 20px;
  }

  @media max-small {
    width: auto;
  }
}

.heroPhotographerButtonWrapper a {
  text-decoration: underline;
}

.heroImageCamera {
  position: relative;
  top: -2px;
  padding-right: 0.625rem;
  width: unset;
  height: 1.5rem;
}
