@value max-medium from "@/styles/variables.module.css";

.ctaButtonContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.sectionHeader {
  text-align: center;
}
