@value max-medium from "@/styles/variables.module.css";

.container {
  margin-top: 50px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.button {
  border-width: 2px;
}

.subHeader {
  margin-top: 25px;
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}

@media max-medium {
  .subHeader {
    padding-bottom: 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
  }
}
