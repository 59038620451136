@value max-small, max-medium, max-large, max-xlarge, min-large, min-medium from "@/styles/variables.module.css";

.container {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  background-color: #a3b9cc;
  padding: 32px 24px;

  @media max-medium {
    position: fixed;
    bottom: -200%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    padding: 16px 12px;
    width: 80%;
    height: 80%;
    overflow-y: scroll;
  }
}

.container.formOpen {
  @media max-medium {
    bottom: 0;
    width: 100%;
  }
}

.title {
  font-weight: 700;
  font-size: 34px;
  font-family: 'DMSerifDisplay';
}

.subTitle {
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 20px;
  font-family: 'Oxygen';
}

.mobileHeader {
  display: none;

  @media max-medium {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 50%;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    transform: translateX(-50%);
    z-index: 1;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #a3b9cc;
    padding: 8px 12px;
    width: 80%;
  }
}

.titleMobile {
  font-weight: 700;
  font-size: 18px;
  font-family: 'DMSerifDisplay';
  text-align: center;
}

.subTitleMobile {
  font-weight: 700;
  font-size: 16px;
  font-family: 'Oxygen';
  text-align: center;
  text-decoration: underline;
}

.closeIcon {
  display: none !important;

  @media max-medium {
    display: block !important;
    position: absolute;
    top: 24px;
    right: 12px;
    cursor: pointer;
  }
}
