@value max-medium from "@/styles/variables.module.css";
.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.container a {
  border-width: 2px;
  padding: 15px 18px;
}

.item {
  display: flex;
  flex-basis: 33%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  padding: 0 25px;
}

@media max-medium {
  .item {
    flex-basis: 99%;
    padding: 0;
  }
  .container a {
    width: 100%;
  }
}

.header {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
}

.text {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-family: Oxygen;
  letter-spacing: 0em;
  text-align: center;
}
