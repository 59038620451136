@value max-medium, min-medium, desktop-m, desktop-s, desktop-l, desktop-xxl tablet from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;

  @media max-medium {
    margin-top: 24px;
  }
}

.wrapperContent {
  position: relative;
  padding: 0 16px;
  width: 100%;
  max-width: 1440px;

  @media tablet {
    padding: 0 48px;
  }

  @media desktop-s {
    padding: 0 24px;
  }

  @media desktop-m {
    padding: 0 48px;
  }

  @media desktop-xxl {
    padding: 0;
  }
}

.wrapperContent.sliderSection {
  padding-right: 0;
  padding-left: 16px;
  width: 100%;
  max-width: none;

  @media tablet {
    padding-left: 48px;
  }

  @media desktop-s {
    padding-left: 24px;
  }

  @media desktop-m {
    padding-left: 48px;
  }

  @media desktop-l {
    padding-left: 0;
  }
}
