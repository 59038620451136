.input {
  border: 1px solid #d9d9d9;
  border-radius: 0;
  background-color: #fff;
  padding: 16px;
  height: 50px;
  color: #000000;
  font-size: 14px;
}

.input:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.input:focus {
  outline: none;
  border: 1px solid #000000;
}
