@value max-medium from "@/styles/variables.module.css";

.modalContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: white;
  padding: 30px;
  width: 611px;
  height: 248px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

@media max-medium {
  .modalContent {
    top: 80%;
    margin-bottom: 10px;
    width: 100%;
    height: 300px;
  }
}

.modalContentDetail {
  display: flex;
  gap: 1.25rem;
}

.previewImageContainer {
  width: 100px;
  height: 100px;
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.previewDetailContainer {
  padding: 0.2rem;
}

.previewDetailHeader {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}

.previewDetailPrice,
.previewDetailLabel {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.link {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
}
.link:hover {
  color: #48acfe;
}

.bottomContainer {
  display: flex;
  gap: 1rem;
  margin-top: 2.5rem;
  width: 100%;
}

.bottomContainer > * {
  flex: 1;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
}
