@value max-medium, min-medium from "@/styles/variables.module.css";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;

  @media max-medium {
    margin-top: 24px;
  }
}

.wrapperContent {
  position: relative;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%;
  max-width: 1170px;

  @media max-medium {
    width: 100%;
  }
}

@media min-medium {
  .wrapperContent.sliderSection {
    margin-top: 3.125rem;
  }
}

@media max-medium {
  .wrapperContent.sliderSection {
    padding-right: 0px;
    padding-left: 0px;
    width: 100%;
  }
}
