.container {
  border: 1px solid #d9d9d9;
  width: 200px;
  height: 180px;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  gap: 20px;
  padding: 20px;
}

.cardTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.button {
  cursor: pointer;
  outline: none;
  border: 1px solid #d9d9d9;
  background-color: transparent;
  padding: 4px;
}
