@value max-small, max-medium, max-large, max-xlarge, min-small, min-medium, min-large, min-xlarge from "@/styles/variables.module.css";

.heroContainer {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;

  @media max-xlarge {
    align-items: center;
  }

  @media max-medium {
    flex-direction: column;
    height: 100%;
  }
}

.heroCardContainer {
  z-index: 1;
  margin: auto 0;
  padding: 20px 0 20px 20px;
  width: 670px;

  @media max-medium {
    padding: 15px;
    width: 100%;
  }
}

.reverse .heroCardContainer {
  @media min-large {
    padding: 20px 0;
  }
}

.transparentBackground {
  @media max-medium {
    background-color: transparent !important;
  }
}

.imageContainer {
  position: relative;
  width: 500px;
  height: 100%;

  @media max-xlarge {
    width: 40%;
  }

  @media max-large {
    width: 45%;
  }

  @media max-medium {
    width: 100%;
  }
}

.pointer {
  cursor: pointer;
}

.heroImage {
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;

  @media max-medium {
    min-height: 350px;
    max-height: 350px;
    object-position: 0 -20px;
  }
}

@media min-large {
  .heroContent {
    margin-left: -15%;
  }
}

.imageChip {
  position: absolute;
  right: 15px;
  bottom: 15px;

  border: none;
  background: #000000;
  padding: 8px 12px;
  height: 34px;
  color: #f8f8f8;
  font-weight: 700;
  font-size: 12px;
  font-family: Oxygen;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  @media max-small {
    display: none;
  }
}
