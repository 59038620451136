@value max-medium, min-medium from "@/styles/variables.module.css";

.button,
.favoriteButton {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  border: 1.5px solid #000;
  background-color: #fff;
  padding: 15px;
  height: 50px;
  color: #000;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Oxygen';
  letter-spacing: 1.6px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.favoriteButton:hover {
  @media min-medium {
    filter: invert(100%);
    border: 1.5px solid #fff;
  }
}

.button:hover {
  @media min-medium {
    background-color: #000;
    color: #fff;
  }
}

.button:hover svg path,
.favoriteButton:hover svg path {
  @media min-medium {
    fill: #fff;
  }
}

.button svg path,
.favoriteButton svg path {
  transition: all 0.3s ease;
}

@media max-medium {
  .button,
  .favoriteButton {
    justify-content: center;
    margin-top: 10px;
    margin-right: 0;
  }
}

.black {
  background: #000000;
  color: #ffffff;
}

.transparent {
  border-color: #ffffff;
  background: transparent;
  color: #ffffff;
}

.noBorder {
  border: none;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
