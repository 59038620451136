.container {
  display: flex;
  gap: 1rem;
  margin-bottom: 3rem;
}

.selectContainer {
  flex: 1;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 80px;
}
