@value max-medium,max-small from "@/styles/variables.module.css";

.container {
  padding: 54px 69px;
}

@media max-medium {
  .container {
    padding: 30px;
  }
}

.cardHeader {
  margin: 0;
  font-weight: 500;
  font-size: 68px;
  line-height: 66px;
  font-family: DM Serif Display;
  letter-spacing: 0em;
  text-align: left;
}

@media max-medium {
  .cardHeader {
    font-weight: 400;
    font-size: 26px;
    line-height: 30px;
  }
}

.cardText {
  margin-bottom: 44px;
  width: 500px;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: Oxygen;
}

@media max-medium {
  .cardText {
    width: auto;
  }
}

.buttonContainer {
  display: flex;
  column-gap: 10px;
}

@media max-small {
  .buttonContainer {
    flex-direction: column;
  }
}

.button {
  border: 2px solid #000000;
  padding: 15px 18px;
}
