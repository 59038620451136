@value desktop-l,desktop-xxl,desktop-m,desktop-s,tablet,light-peach from "@/styles/variables.module.css";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
  margin: 24px 0;

  @media tablet {
    margin: 28px 0;
  }

  @media desktop-s {
    gap: 16px;
  }

  @media desktop-m {
    gap: 28px;
  }
}

.topWrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
  max-width: 1440px;

  @media desktop-s {
    flex-direction: row;
    gap: 16px;
    padding: 0 24px;
  }

  @media desktop-m {
    gap: 28px;
    padding: 0 48px;
  }

  @media desktop-xxl {
    padding: 0;
  }
}

.specialCardWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mainCardWrapper {
  margin: 0 16px;

  @media desktop-s {
    display: flex;
    flex: 1;
    margin: 0;
  }
}
