.container {
    background: #343e47;
    padding: 12px;
    color: #ffffff;
    cursor: help;
    margin: 12px 0;
  }
  
  .span {
    margin-left: 10px;
  }