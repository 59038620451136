.container {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
}

.image {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.ctaWrapper {
  display: flex;
  column-gap: 24px;
  justify-content: center;
}
