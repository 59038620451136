.contentContainer {
  display: flex;
  flex-direction: column;
}

.header {
  margin: 50px 30px 0px 30px;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0em;
}

.text {
  margin: 10px 30px 0px 30px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.heroCreateAccountSingUp {
  margin: auto;
  margin-top: 24px;
  margin-bottom: 40px;
  margin-left: 30px;
  border-width: 2px;
  padding: 15px 18px;
}
