@value desktop-s, max-mobile, max-tablet, tablet from '@/styles/variables.module.css';

.visibleOnDesktop {
  display: none;

  @media desktop-s {
    display: block !important;
  }
}

.visibleOnTablet {
  display: none;

  @media (tablet) and (max-tablet) {
    display: block !important;
  }
}

.visibleOnMobile {
  display: none;

  @media (max-mobile) {
    display: block !important;
  }
}
