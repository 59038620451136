@value max-small, max-medium, max-large, max-xlarge from "@/styles/variables.module.css";

.header {
  width: 100%;
  color: #000000;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;

  @media max-small {
    padding-right: 30px;
    padding-left: 30px;
  }
}
