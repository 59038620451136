@value max-small, max-medium, max-large, max-xlarge, min-small, min-medium, min-large, min-xlarge from "@/styles/variables.module.css";

.wrapper {
  position: relative;
  flex: 1;
  margin-bottom: 1.8rem;
}
.inputWrapper {
  display: flex;
  position: relative;
}

.inputWrapper button {
  display: flex;
  position: absolute;
  right: 4px;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  height: 100%;
}

.input {
  border: 1px solid #d9d9d9;
  border-radius: 0;
  background-color: #fff;
  padding: 16px;
  width: 100%;
  height: 50px;
  color: #000000;
  font-size: 14px;
}

.input:disabled {
  cursor: not-allowed;
  background-color: #f5f5f5;
}

.input:focus {
  outline: none;
  border: 1px solid #d9d9d9;
}

.options {
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
  margin: 0;
  box-shadow:
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 0;
  width: 100%;
  max-height: 330px;
  overflow-y: auto;
  color: #000000;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  font-family: Oxygen;
  list-style: none;
  text-align: center;
}

.options > li {
  width: 100%;
}

.option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0.85;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffffff;
  padding-left: 10px;
  width: 100%;
  height: 50px;
  font-weight: 400;
  font-size: 14px;
}

.selected {
  background-color: #000000;
  color: #ffffff;
  font-weight: 600;
}

.active {
  background-color: #f5f5f5;
}

.active.selected {
  background-color: #000000;
  color: #ffffff;
}

.option:hover {
  background-color: #f5f5f5;
}

.option.selected:hover {
  background-color: #000000;
  color: #ffffff;
}

.arrowUp {
  transform: rotate(180deg);
}
