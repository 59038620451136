@value desktop-s, light-peach from "@/styles/variables.module.css";

.heroContainer {
  margin: 0;
  background-color: light-peach;
}

.sectionContainer {
  margin: 28px 0;

  @media desktop-s {
    margin: 48px 0;
  }
}
