@value max-medium from "@/styles/variables.module.css";
@value padding: 12px;

.articleCardTitle {
  margin: 0;
  background: #ffffff;
  padding: 10px 14px;
  width: 100%;
  color: #000000;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-transform: uppercase;

  @media max-medium {
    font-size: 20px;
    line-height: 24px;
  }
}

.favoriteContainer {
  position: absolute;
  top: padding;
  right: padding;
}

.contentContainer {
  position: absolute;
  bottom: padding;
  left: padding;
  padding-right: padding;
  max-width: calc(100% - padding);
}
