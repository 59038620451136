@value secondary-font from "@/styles/variables.module.css";

.container {
  display: flex;
}

.list {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.listItem {
  display: flex;
}

.arrow {
  margin: 0 4px;
}

.link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  font-family: secondary-font;
  text-transform: uppercase;
  white-space: nowrap;
}

.linkLast {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  font-family: secondary-font;
  text-align: left;
}
