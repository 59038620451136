@value max-small, max-medium, max-large, max-xlarge from "@/styles/variables.module.css";

/* div */
.contentContainer {
  display: flex;
  flex-direction: column;
  padding: 40px 50px;

  @media max-large {
    padding: 30px;
  }
}

/* h4 */
.heroCreateAccountTitle {
  color: #000000;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 10px;

  @media max-medium {
    color: #343e47;
    font-size: 20px;
  }
}

/* p */
.heroCreateAccountSubTitle {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media max-medium {
    color: #343e47;
  }
}
/* ul */
.heroCreateAccountList {
  list-style-type: none;
  margin-top: 24px;
  padding-left: 0;
}

/* li */
.heroCreateAccountItem {
  color: #000000;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;

  @media max-large {
    font-size: 16px;
    line-height: 24px;
  }

  @media max-medium {
    color: #343e47;
    font-size: 14px;
    line-height: 22px;
  }
}
/* div */
.heroCreateAccountIconWrapper {
  width: 30px;
  height: 30px;
  margin-right: 20px;

  @media max-medium {
    margin-right: 15px;
    width: 24px;
    height: 24px;
  }
}

/* CheckCircleOutlined */
.heroCreateAccountCheckIcon {
  color: #dabbb7;
  font-size: 30px;

  @media max-medium {
    font-size: 24px;
  }
}

/* Button */
.heroCreateAccountSingUp {
  margin-right: auto;

  @media max-medium {
    width: 100%;
  }
}
