/* adding "a" tag for higher precedence */
a.button {
  display: flex;
  margin: 0px;
  margin-bottom: 8px;
  border: 1px solid #d9d9d9;
  padding: 9px;
  width: 300px;
  width: 100%;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  font-family: Oxygen;
  letter-spacing: 0.1em;
  text-align: center;
}

.buttonText {
  flex: 1;
}

.errorMessage {
  margin-top: 8px;
  margin-bottom: 16px;
  color: red;
  font-size: 12px;
  text-align: left;
}
