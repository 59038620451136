@value modalZIndex, modalOverlayZIndex, secondary-font, main-dark, main-font from '@/styles/variables.module.css';

.container {
  display: flex;
  position: absolute;
  top: 0;
  left: -80%;
  flex-direction: column;
  z-index: modalZIndex;
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
  width: 80%;
  height: 100dvh;
}

.container.open {
  transform: translateX(100%);
}

.overlay.open {
  position: absolute;
  top: 0;
  left: 0;
  z-index: modalOverlayZIndex;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100dvw;
  height: 100dvh;
}

.topWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: main-dark;
  padding: 0 13px;
  height: 100%;
}

.back {
  display: flex;
  column-gap: 12px;
  align-items: center;
  border: none;
  background-color: transparent;
  padding-left: 16px;
  width: 100%;
  height: 100%;
  color: main-dark;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  font-family: secondary-font;
  letter-spacing: 0.01em;
}

.nav {
  position: relative;
  flex: 1;
  max-height: calc(100dvh - 50px - 90px);
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.nav::-webkit-scrollbar {
  display: none;
}

.navList {
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  transition: display 0.3s ease-in-out;
  margin: 0;
  padding: 36px 16px;
}

.navList.close {
  transform: translateX(-100dvw);
}

.navItem {
  list-style: none;
}

.navItemWithRowTitle {
  padding-left: 16px;
}

.navSubRowTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
}

.navLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
  width: 100%;
  color: main-dark;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: secondary-font;
}

.navLink:visited {
  color: main-dark;
}

.navListSubcategory {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100dvw);
  transition: transform 0.3s ease-in-out;
  background: #fff;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.navListSubcategory::-webkit-scrollbar {
  display: none;
}

.navListSubcategory.open {
  transform: translateX(0);
}

.subcategoryTitle {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  font-family: main-font;
  list-style: none;
}

.subcategoryTitleLink {
  display: flex;
  column-gap: 10px;
  align-items: center;
  color: main-dark;
}

.subcategoryTitleLink:visited {
  color: main-dark;
}

.bottomWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: main-dark;
  width: 100%;
  height: 90px;
}

.bottomLinkWrapper {
  display: flex;
  column-gap: 12px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: main-dark;
}
