@value desktop-s, main-font, secondary-font, main-dark  from "@/styles/variables.module.css";

.container {
  position: relative;
  overflow: hidden;
}

.content {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.title {
  margin: 0;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  font-family: main-font;
  letter-spacing: -0.72px;
  text-align: center;

  @media desktop-s {
    font-weight: 400;
    font-size: 48px;
    line-height: 64px;
  }
}

.image {
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.5s;
  width: 100%;
  height: 100%;
}

.container:hover .image {
  @media desktop-s {
    transform: scale(110%);
  }
}

.container:hover .button {
  @media desktop-s {
    background-color: #fff;
    color: main-dark;
  }
}

.button.button {
  border: 1px solid #fff;
  border-radius: 2px;
  background-color: transparent;
  padding: 10px 24px;
  width: auto;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: secondary-font;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.shadow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    0deg,
    rgba(19, 19, 19, 0.4) 0%,
    rgba(19, 19, 19, 0.4) 100%
  );
}

.container:hover .shadow {
  @media desktop-s {
    background: linear-gradient(
      0deg,
      rgba(19, 19, 19, 0.2) 0%,
      rgba(19, 19, 19, 0.2) 100%
    );
  }
}
