@value max-small, max-xlarge, main-font from "@/styles/variables.module.css";

.heading {
  margin-top: 24px;
  width: 100%;
  color: #000;
  font-weight: 400;
  font-size: 28px;
  line-height: 30px;
  font-family: main-font;

  @media max-xlarge {
    margin-top: 30px;
  }

  @media max-large {
    margin-top: 0;
  }
}
