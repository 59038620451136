.chip {
  display: inline-block;
  padding: 10px 14px;
  color: #ffffff;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  border: none;
}
