.linkContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #40a9ff;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
}

.categoryContainer {
  flex: 1 1 30%;
}

.categoryHeader {
  text-transform: capitalize;
}
